import localeAntd from 'antd/es/locale/pt_BR'

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.typeToSearch': 'Busca...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Olá',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Nivel',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
}

export default {
  locale: 'pt-BR',
  localeAntd,
  messages,
}
