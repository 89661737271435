import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/layout/TopBar'
import TopBarDark from 'components/layout/TopBarDark'
import MenuLeft from 'components/layout/MenuLeft'
import MenuTop from 'components/layout/MenuTop'
import Footer from 'components/layout/Footer'
import FooterDark from 'components/layout/FooterDark'
import Sidebar from 'components/layout/Sidebar'

const mapStateToProps = ({ settings, user }) => ({ settings, user })

@withRouter
@connect(mapStateToProps)
class AppLayout extends React.PureComponent {
  render() {
    const {
      children,
      settings: {
        menuLayoutType,
        isContentNoMaxWidth,
        isAppMaxWidth,
        isGrayBackground,
        isSquaredBorders,
        isCardShadow,
        isBorderless,
        isTopbarFixed,
        isGrayTopbar,
        isFooterDark,
        dashGames,
      },
    } = this.props

    return (
      <div className={classNames({ air__layout__grayBackground: isGrayBackground })}>
        <Layout
          className={classNames({
            air__layout__contentNoMaxWidth: isContentNoMaxWidth,
            air__layout__appMaxWidth: isAppMaxWidth,
            air__layout__grayBackground: isGrayBackground,
            air__layout__squaredBorders: isSquaredBorders,
            air__layout__cardsShadow: isCardShadow,
            air__layout__borderless: isBorderless,
          })}
        >
          <Sidebar />
          {menuLayoutType === 'left' && <MenuLeft />}
          {menuLayoutType === 'top' && <MenuTop />}
          <Layout>
            <Layout.Header
              className={classNames('air__layout__header', {
                air__layout__fixedHeader: isTopbarFixed,
                air__layout__headerGray: isGrayTopbar,
              })}
            >
              {dashGames && (
                <div className="logoHeader">
                  <Link to="/dashboard/games">
                    <img
                      src={`${process.env.PUBLIC_URL}/resources/images/logocomplete.png`}
                      alt="Trading Tool Logo"
                    />
                  </Link>
                </div>
              )}
              {menuLayoutType !== 'top-dark' && <TopBar />}
              {menuLayoutType === 'top-dark' && <TopBarDark />}
            </Layout.Header>
            <Layout.Content style={{ height: '100%', position: 'relative' }}>
              {/* <div style={{ background: 'red', width: '100%', textAlign: 'center', color: '#fff' }}>
                SCANNER EM MANUTENÇÃO NAS PROXIMAS 48HRS
              </div> */}
              <div className={!dashGames ? 'air__utils__content' : 'dashGamesContent'}>
                {children}
              </div>
            </Layout.Content>
            <Layout.Footer>
              {!isFooterDark && <Footer />}
              {isFooterDark && <FooterDark />}
            </Layout.Footer>
          </Layout>
        </Layout>
      </div>
    )
  }
}

export default AppLayout
