import axios from 'axios'

const api = axios.create({
  // baseURL: 'http://localhost:5000/v1',
  // baseURL: 'https://app.scoutsporttrade.com/v1',
  // baseURL: 'https://appp.scoutsporttrade.com/v1',
  baseURL: 'https://legacy.ms.scoutsporttrade.com/v1',
  timeout: 720000, // 2
})

export default api
