import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import { deleteMethods } from 'services/methods'
import {
  createMethods,
  getMethods,
  updateMethods,
  updateMethodsSony,
  statusBot,
  changeStatusBot,
  getSonyOrders,
  toggleActiveMethod,
} from 'services/sony'
import actions from './actions'

export function* GET_METHODS() {
  const methods = yield call(getMethods)
  if (methods) {
    yield put({
      type: 'sony/SET_STATE',
      payload: {
        data: methods,
      },
    })
  }
  if (!methods) {
    yield put({
      type: 'sony/SET_STATE',
      payload: {
        data: [],
      },
    })
  }
}

export function* GET_ORDERS_SONY(data) {
  const user = yield select(state => state.user)
  if (user && user.sonyBotIp && user.sonyBotToken) {
    const page = data.payload.page || 1
    const order = data.payload.order && data.payload.order === 'ascend' ? 'ASC' : 'DESC'
    const dateStart = data.payload.dateStart || null
    const dateEnd = data.payload.dateEnd || null
    const methodId = data.payload.methodId || null

    const respOrders = yield call(getSonyOrders, page, order, dateStart, dateEnd, methodId)
    if (respOrders) {
      yield put({
        type: 'sony/SET_STATE',
        payload: {
          orders: respOrders,
        },
      })
    }
  }
}

export function* CREATE_METHOD(data) {
  const respCreateMethods = yield call(createMethods, data.payload)
  if (respCreateMethods) {
    yield history.push('/dashboard/sony/methods/show')
    yield put({
      type: 'sony/UPDATE_METHOD_SONY',
    })
    notification.success({
      message: 'Criado',
      description: 'Método criado.',
    })
  }
}

export function* UPDATE_METHOD(data) {
  const respUpdateMethods = yield call(updateMethods, data.payload)
  if (respUpdateMethods) {
    yield history.push('/dashboard/sony/methods/show')
    yield put({
      type: 'sony/UPDATE_METHOD_SONY',
    })
    notification.success({
      message: 'Atualizado',
      description: 'Método atualizado.',
    })
  }
}

export function* DELETE_METHOD(data) {
  const methods = yield call(deleteMethods, data.payload.id)
  if (methods) {
    yield put({
      type: 'sony/UPDATE_METHOD_SONY',
    })
    yield put({
      type: 'sony/GET_METHODS',
    })
  }
}

export function* UPDATE_METHOD_SONY() {
  const user = yield select(state => state.user)
  if (user && user.sonyBotIp && user.sonyBotToken) {
    const updateSonyMehotds = yield call(updateMethodsSony, user.sonyBotIp, user.sonyBotToken)
    if (!updateSonyMehotds) {
      notification.error({
        message: 'Metodos Sonny',
        description: 'Erro ao sincronizar metodos, tente novamente.',
      })
    }
  }
}

export function* STATUS_SONY() {
  const user = yield select(state => state.user)
  if (user && user.sonyBotIp && user.sonyBotToken) {
    const botStatus = yield call(statusBot, user.sonyBotIp, user.sonyBotToken)
    if (!botStatus) {
      console.log('deu erro')
      return
    }
    yield put({
      type: 'sony/SET_STATE',
      payload: {
        statusBot: botStatus.botStatus,
        loadingBot: botStatus.loading,
      },
    })
  }
}

export function* UPDATE_STATUS_SONY(data) {
  const user = yield select(state => state.user)
  if (user && user.sonyBotIp && user.sonyBotToken) {
    const botStatus = yield call(
      changeStatusBot,
      data.payload.status,
      user.sonyBotIp,
      user.sonyBotToken,
    )
    if (!botStatus) {
      console.log('deu erro')
      return
    }
    yield put({
      type: 'sony/SET_STATE',
      payload: {
        statusBot: botStatus.botStatus,
        loadingBot: botStatus.loading,
      },
    })
  }
}

export function* UPDATE_TOGGLE_METHOD_SONY({ payload }) {
  yield put({
    type: 'sony/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield call(toggleActiveMethod, payload.value, payload.methodId)

  yield put({
    type: 'sony/GET_METHODS',
  })

  yield put({
    type: 'sony/UPDATE_METHOD_SONY',
  })

  yield put({
    type: 'sony/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_METHODS, GET_METHODS),
    takeEvery(actions.CREATE_METHOD, CREATE_METHOD),
    takeEvery(actions.UPDATE_METHOD, UPDATE_METHOD),
    takeEvery(actions.DELETE_METHOD, DELETE_METHOD),
    takeEvery(actions.UPDATE_METHOD_SONY, UPDATE_METHOD_SONY),
    takeEvery(actions.STATUS_SONY, STATUS_SONY),
    takeEvery(actions.UPDATE_STATUS_SONY, UPDATE_STATUS_SONY),
    takeEvery(actions.GET_ORDERS_SONY, GET_ORDERS_SONY),
    takeEvery(actions.UPDATE_TOGGLE_METHOD_SONY, UPDATE_TOGGLE_METHOD_SONY),
  ])
}
