import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import { login, register, indicators } from 'services/user'
import actions from './actions'
import { getMethods } from '../../services/methods'

export function* LOGIN({ payload }) {
  const { email, password, recaptcha } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const dataUser = yield call(login, email, password, recaptcha)

  if (!dataUser) {
    yield history.push('/')
  }

  if (dataUser && dataUser.token) {
    const indicatorsResp = yield call(indicators, dataUser.token)

    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: dataUser.id || null,
        name: dataUser.name || null,
        email: dataUser.email || null,
        avatar: '',
        role: dataUser.role || null,
        inputRole: dataUser.inputRole || null,
        token: dataUser.token || null,
        sonyBotIp: dataUser.sonyBotIp || null,
        sonyBotToken: dataUser.sonyBotToken || null,
        authorized: true,
        mRicardo: dataUser.mRicardo || 0,
        indicators: indicatorsResp || [],
      },
    })

    if (dataUser && dataUser.role > 2) {
      const methods = yield call(getMethods)
      if (methods) {
        yield put({
          type: 'methods/SET_STATE',
          payload: {
            data: methods,
          },
        })
      }
    }

    yield history.push('/')
    notification.success({
      message: 'Sucesso',
      description: 'Bem vindo ao trading tool',
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* REGISTER({ payload }) {
  const { email, password, recaptcha, name, lastname } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const dataUser = yield call(register, email, password, name, lastname, recaptcha)

  if (dataUser && dataUser.token) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: dataUser.id || null,
        name: dataUser.firstName || null,
        email: dataUser.email || null,
        avatar: '',
        role: dataUser.role || null,
        inputRole: dataUser.inputRole || null,
        token: dataUser.token || null,
        sonyBotIp: dataUser.sonyBotIp || null,
        sonyBotToken: dataUser.sonyBotToken || null,
        authorized: true,
      },
    })

    if (dataUser && dataUser.role > 2) {
      const methods = yield call(getMethods)
      if (methods) {
        yield put({
          type: 'methods/SET_STATE',
          payload: {
            data: methods,
          },
        })
      }

      notification.success({
        message: 'Sucesso',
        description: 'Bem vindo ao trading tool',
      })
    }

    yield history.push('/system/login')
    notification.success({
      message: 'Cadastro feito com sucesso!',
      description: 'Aguarde a liberação do seu acesso',
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      email: '',
      avatar: '',
      role: null,
      token: null,
      authorized: false,
      loading: false,
    },
  })

  yield put({
    type: 'games/SET_STATE',
    payload: {
      interval: false,
      tab: 1,
      data: [],
      favorite: [],
      gamesLive: 0,
      gamesMethods: 0,
      filterMethod: 'all',
      selectGame: null,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOGOUT, LOGOUT),
  ])
}
