const actions = {
  CREATE_METHOD: 'methods/CREATE_METHOD',
  UPDATE_METHOD: 'methods/UPDATE_METHOD',
  DELETE_METHOD: 'methods/DELETE_METHOD',
  GET_METHODS: 'methods/GET_METHODS',
  GET_METHODS_ORDERS: 'methods/GET_METHODS_ORDERS',
  SET_STATE: 'methods/SET_STATE',
}

export default actions
