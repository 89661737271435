const actions = {
  CREATE_METHOD: 'sony/CREATE_METHOD',
  UPDATE_METHOD: 'sony/UPDATE_METHOD',
  UPDATE_METHOD_SONY: 'sony/UPDATE_METHOD_SONY',
  DELETE_METHOD: 'sony/DELETE_METHOD',
  GET_METHODS: 'sony/GET_METHODS',
  SET_STATE: 'sony/SET_STATE',
  STATUS_SONY: 'sony/STATUS_SONY',
  UPDATE_STATUS_SONY: 'sony/UPDATE_STATUS_SONY',
  GET_ORDERS_SONY: 'sony/GET_ORDERS_SONY',
  UPDATE_TOGGLE_METHOD_SONY: 'sony/UPDATE_TOGGLE_METHOD_SONY',
}

export default actions
