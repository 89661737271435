import actions from './actions'

const initialState = {
  tab: 1,
  data: [],
  favorite: [],
  gamesLive: 0,
  gamesMethods: 0,
  filterMethod: 'all',
  selectGame: null,
  indicators: null,
  loading: false,
}

export default function gameReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
