import React from 'react'
import { Link } from 'react-router-dom'
import style from '../style.module.scss'

class Error404 extends React.Component {
  render() {
    return (
      <div className={style.errors}>
        <div className="pt-4 pb-4 d-flex align-items-end mt-auto">
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/logofooter.png`}
            alt="Trading Tool"
          />
          <div className="air__utils__logo__text">
            <div className="air__utils__logo__name text-uppercase text-dark font-size-21">
              Trading Tool
            </div>
            <div className="air__utils__logo__descr text-uppercase font-size-12 text-gray-6">
              References for success
            </div>
          </div>
        </div>
        <div className={`${style.container} pl-5 pr-5 pt-5 pb-5 mb-auto text-dark font-size-30`}>
          <div className="font-weight-bold mb-3">Pagina não encontrada</div>
          <div>Essa pagina não existe</div>
          <div className="font-weight-bold font-size-70 mb-1">404 —</div>
          <Link to="/" className="btn btn-outline-primary width-100">
            Voltar
          </Link>
        </div>
      </div>
    )
  }
}

export default Error404
