import { notification } from 'antd'
import api from './api'

export default {
  setupInterceptors: store => {
    api.interceptors.request.use(
      requests => {
        const userState = store.getState().user
        const token = userState && userState.token ? userState.token : null

        if (token) {
          // eslint-disable-next-line no-param-reassign
          requests.headers.authorization = token
        }

        return requests
      },
      error => Promise.reject(error),
    )

    // Response interceptors
    api.interceptors.response.use(
      response => response,
      error => {
        if (error && error.response && error.response.status === 401) {
          notification.warning({
            message: 'Acesso expirado',
            description: 'Seu acesso está invalido.',
          })
          store.dispatch({
            type: 'user/LOGOUT',
          })
          return Promise.reject(error)
        }

        const errors = []
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          typeof error.response.data.messages === 'object'
        ) {
          error.response.data.messages.forEach(el => {
            if (el.message) {
              errors.push(el.message)
            }
          })
        }
        if (errors && errors.length > 0) {
          notification.warning({
            message: 'Erro',
            description: 'Aconteceu um erro, tente novamente.',
          })
        }
        return Promise.reject(error)
      },
    )
  },
}
