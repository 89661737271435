import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import games from './games/sagas'
import methods from './methods/sagas'
import sony from './sony/sagas'

export default function* rootSaga() {
  yield all([user(), menu(), settings(), games(), methods(), sony()])
}
