import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Switch from 'react-router-transition-switch'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'

import Layout from 'layouts'
import Loader from 'components/layout/Loader'
import NotFoundPage from 'pages/system/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // Dashboards
  {
    path: '/dashboard/punterodds',
    Component: loadable(() => import('pages/dashboard/punterOdds')),
    exact: true,
  },
  {
    path: '/dashboard/dashgames',
    Component: loadable(() => import('pages/dashboard/dashGames')),
    exact: true,
  },
  {
    path: '/dashboard/games',
    Component: loadable(() => import('pages/dashboard/games')),
    exact: true,
  },
  {
    path: '/dashboard/pastgames',
    Component: loadable(() => import('pages/dashboard/games/pastGames')),
    exact: true,
  },
  {
    path: '/dashboard/pastgames/search',
    Component: loadable(() => import('pages/dashboard/games/pastGamesSearch')),
    exact: true,
  },
  {
    path: '/dashboard/pastgames/:id',
    Component: loadable(() => import('pages/dashboard/games/pastGame')),
    exact: true,
  },
  {
    path: '/dashboard/games/search/basic',
    Component: loadable(() => import('pages/dashboard/search/basic')),
    exact: true,
  },
  {
    path: '/dashboard/games/search/advanced',
    Component: loadable(() => import('pages/dashboard/search/advanced')),
    exact: true,
  },
  {
    path: '/dashboard/methods/show',
    Component: loadable(() => import('pages/dashboard/methods/show')),
    exact: true,
  },
  {
    path: '/dashboard/methods/create',
    Component: loadable(() => import('pages/dashboard/methods/createUpdate')),
    exact: true,
  },
  {
    path: '/dashboard/methods/:id',
    Component: loadable(() => import('pages/dashboard/methods/createUpdate')),
    exact: true,
  },
  {
    path: '/dashboard/prelive',
    Component: loadable(() => import('pages/dashboard/prelive')),
    exact: true,
  },
  {
    path: '/dashboard/sony/methods/show',
    Component: loadable(() => import('pages/dashboard/botsony/show')),
    exact: true,
  },
  {
    path: '/dashboard/sony',
    Component: loadable(() => import('pages/dashboard/botsony/infos')),
    exact: true,
  },
  {
    path: '/dashboard/sony/methods/create',
    Component: loadable(() => import('pages/dashboard/botsony/createUpdate')),
    exact: true,
  },
  {
    path: '/dashboard/sony/methods/:id',
    Component: loadable(() => import('pages/dashboard/botsony/createUpdate')),
    exact: true,
  },
  {
    path: '/dashboard/sony/shared',
    Component: loadable(() => import('pages/dashboard/botsony/sharedMethods')),
  },
  {
    path: '/dashboard/sony/configs',
    Component: loadable(() => import('pages/dashboard/botsony/configs')),
  },
  {
    path: '/dashboard/filterCard',
    Component: loadable(() => import('pages/dashboard/cardFilterColors')),
    exact: true,
  },
  {
    path: '/dashboard/orders/methods/show',
    Component: loadable(() => import('pages/dashboard/orders/methods')),
    exact: true,
  },
  {
    path: '/dashboard/orders/open',
    Component: loadable(() => import('pages/dashboard/orders/open')),
    exact: true,
  },
  {
    path: '/dashboard/orders',
    Component: loadable(() => import('pages/dashboard/orders/myOrders')),
    exact: true,
  },
  {
    path: '/dashboard/fulltips',
    Component: loadable(() => import('pages/dashboard/fulltips/index')),
    exact: true,
  },
  {
    path: '/admin',
    Component: loadable(() => import('pages/dashboard/admin/users')),
    exact: true,
  },
  {
    path: '/indicadores',
    Component: loadable(() => import('pages/dashboard/indicators')),
    exact: true,
  },

  // System Pages
  {
    path: '/system/sofa',
    Component: loadable(() => import('pages/system/sofa')),
    exact: true,
  },
  {
    path: '/system/login',
    Component: loadable(() => import('pages/system/login')),
    exact: true,
  },
  {
    path: '/system/register',
    Component: loadable(() => import('pages/system/register')),
    exact: true,
  },
  {
    path: '/system/recovery',
    Component: loadable(() => import('pages/system/recovery')),
    exact: true,
  },
  {
    path: '/system/reset',
    Component: loadable(() => import('pages/system/reset')),
    exact: true,
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/system/404')),
    exact: true,
  },
  {
    path: '/system/500',
    Component: loadable(() => import('pages/system/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Router extends React.Component {
  render() {
    const {
      history,
      settings: { routerAnimation },
    } = this.props
    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={props => {
              const {
                children,
                location: { pathname },
              } = props
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={pathname}
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    {children}
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          >
            <Route exact path="/" render={() => <Redirect to="/dashboard/games" />} />
            {routes.map(({ path, Component, exact }) => (
              <Route path={path} key={path} exact={exact}>
                <Component />
              </Route>
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ConnectedRouter>
    )
  }
}

export default Router
