import { notification } from 'antd'
import api from './api'

// eslint-disable-next-line import/prefer-default-export
export async function getMethods() {
  try {
    const resp = await api.get('/method')

    if (resp && resp.data && resp.data.length) {
      const filter = resp.data.filter(el => el.type === 'scanner')

      return filter
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha nos métodos',
      description: 'Não conseguimos pegar os seus métodos.',
    })
  }
}

export async function createMethods(payload) {
  try {
    const resp = await api.post('/method', payload)

    if (resp && resp.data && resp.data.id) {
      return resp.data.id
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha ao criar o método',
      description: 'Tente novamente.',
    })
  }
}

export async function updateMethods(payload) {
  try {
    const data = {
      name: payload && payload.name ? payload.name : null,
      conditions: payload && payload.conditions ? payload.conditions : [],
    }

    if (payload.color) {
      data.color = payload.color
    }

    if (payload.description) {
      data.description = payload.description
    }

    data.conditionType = payload.conditionType ? payload.conditionType : 1

    data.stake = '0.00'

    if (payload.icon) {
      data.icon = payload.icon
    }

    const resp = await api.put(`/method/${payload.id}`, data)

    if (resp && resp.data && resp.data.id) {
      return resp.data.id
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha ao criar o método',
      description: 'Tente novamente.',
    })
  }
}

export async function deleteMethods(id) {
  try {
    const resp = await api.delete(`/method/${id}`)

    if (resp && resp.data && resp.data.methodId) {
      return resp.data.methodId
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha nos métodos',
      description: 'Não conseguimos deletar o método.',
    })
  }
}

export async function clearGRProfit(id) {
  try {
    await api.get(`/method/clear/${id}`)

    return true
  } catch (error) {
    return notification.warning({
      message: 'Falha zerar metodo',
      description: 'Não conseguimos zerar o método. Tente novamente mais tarde.',
    })
  }
}

export async function createOperationMethod(name, abreviation) {
  try {
    const resp = await api.post('/method/operation', {
      name,
      abreviation,
    })

    if (resp && resp.data && resp.data.id) {
      return resp.data.id
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha ao criar o método',
      description: 'Tente novamente.',
    })
  }
}

export async function getOperationsMethod() {
  try {
    const resp = await api.get('/method/operation')

    if (resp && resp.data) {
      return resp.data
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha ao consultar métodos',
      description: 'Tente novamente.',
    })
  }
}

export async function deleteOperationsMethod(id) {
  try {
    const resp = await api.delete(`/method/operation/${id}`)

    if (resp && resp.data) {
      return resp.data
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha ao deletar métodos',
      description: 'Tente novamente.',
    })
  }
}

export async function getMethodsRicardo() {
  try {
    const resp = await api.get('/method/methodsRicardo')

    if (resp && resp.data) {
      return resp.data
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha ao pegar métodos',
      description: 'Tente novamente.',
    })
  }
}

export async function getMethodsSonny() {
  try {
    const resp = await api.get('/method/sonny')

    if (resp && resp.data) {
      return resp.data
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha ao pegar métodos',
      description: 'Tente novamente.',
    })
  }
}
