import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import Interceptors from './services/interceptors'

import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import Router from './router'

// app styles
import './global.scss'

// persist settings
const persistConfig = {
  key: 'app2432',
  storage,
  whitelist: ['user', 'methods'],
}

// middlewared
const history = createBrowserHistory()
const pReducer = persistReducer(persistConfig, reducers(history))
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}
const store = createStore(pReducer, compose(applyMiddleware(...middlewares)))
const persistor = persistStore(store)
Interceptors.setupInterceptors(store)
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history} />
      </PersistGate>
    </Localization>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
export { store, history }
