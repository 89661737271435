import { notification } from 'antd'
import api from './api'

// eslint-disable-next-line import/prefer-default-export
export async function getMethods() {
  try {
    const resp = await api.get('/method')

    if (resp && resp.data && resp.data.length) {
      const filter = resp.data.filter(el => el.type === 'botsonny')
      return filter
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha nos métodos',
      description: 'Não conseguimos pegar os seus métodos.',
    })
  }
}

export async function updateMethods(payload) {
  try {
    const data = {
      name: payload && payload.name ? payload.name : null,
      stake: payload.stake ? payload.stake : null,
      strategyType: payload.strategyType ? payload.strategyType : null,
      marketType: payload.marketType ? payload.marketType : null,
      side: payload.side,
      type: 'botsonny',
    }

    if (payload && payload.description) {
      data.description = payload.description
    }

    if (!payload.ref_id && payload.conditions) {
      const mapRemoveGroupNullC = payload.conditions.filter(v => {
        return v.group.length
      })
      data.conditions = JSON.stringify(mapRemoveGroupNullC)
    }

    if (!payload.ref_id && payload.exitConditions) {
      const mapRemoveGroupNull = payload.exitConditions.filter(v => {
        return v.group.length
      })
      data.exitConditions = JSON.stringify(mapRemoveGroupNull)
    }

    const resp = await api.put(`/method/${payload.id}`, data)

    if (resp && resp.data && resp.data.id) {
      return resp.data.id
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha ao criar o método',
      description: 'Tente novamente.',
    })
  }
}

export async function createMethods(payload) {
  try {
    const data = {
      name: payload.name,
      conditions: payload.conditions ? JSON.stringify(payload.conditions) : null,
      stake: payload.stake ? payload.stake : null,
      strategyType: payload.strategyType ? payload.strategyType : null,
      marketType: payload.marketType ? payload.marketType : null,
      side: payload.side,
      type: 'botsonny',
    }

    if (payload && payload.description) {
      data.description = payload.description
    }

    if (payload.exitConditions) {
      const mapRemoveGroupNull = payload.exitConditions.filter(v => {
        return v.group.length
      })
      data.exitConditions = JSON.stringify(mapRemoveGroupNull)
    }

    const resp = await api.post('/method', data)

    if (resp && resp.data && resp.data.id) {
      return resp.data.id
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha ao criar o método',
      description: 'Tente novamente.',
    })
  }
}

export async function getSonyOrders(page, orderBy, dateStart, dateEnd, methodId) {
  try {
    const dateParam = dateStart && dateEnd ? `&startDate=${dateStart}&endDate=${dateEnd}` : ''
    const methodIdParam = methodId ? `&methodId=${methodId}` : ''

    const resp = await api.get(`/orders?page=${page}&order=${orderBy}${dateParam}${methodIdParam}`)
    if (resp && resp.data) {
      return resp.data
    }
    return null
  } catch (e) {
    return null
  }
}

export async function statusBot(serverSonyIp, tokenServerSony) {
  if (serverSonyIp && tokenServerSony) {
    const resp = await api.get('/sony/status')
    if (resp && resp.data) {
      return resp.data
    }
  }
  return null
}

export async function changeStatusBot(payload, serverSonyIp, tokenServerSony) {
  if (payload && serverSonyIp && tokenServerSony) {
    const resp = await api.post('/sony/changeStatus', {
      status: payload,
    })
    if (resp && resp.data) {
      return resp.data
    }
  }
  return null
}

export async function updateMethodsSony(serverSonyIp, tokenServerSony) {
  if (serverSonyIp && tokenServerSony) {
    const resp = await api.get(`/sony/method`, {
      headers: {
        authorization: tokenServerSony,
      },
    })
    if (resp && resp.data) {
      return resp.data
    }
  }
  return null
}

export async function toggleActiveMethod(value, methodId) {
  const newValue = value ? 1 : 0
  const resp = await api.put(`/method/active/${methodId}`, {
    activeState: newValue,
  })

  if (!resp || !resp.data) {
    notification.error({
      message: 'Erro ao atualizar o método',
      description: 'Tente mais tarde.',
    })
  }
}

export async function getLogs(serverSonyIp, tokenServerSony) {
  if (serverSonyIp) {
    const resp = await api.get(`/sony/logs`, {
      headers: {
        authorization: tokenServerSony,
      },
    })
    if (resp && resp.data) {
      return resp.data
    }
  }
  return null
}
