import { all, takeEvery, call, put, select } from 'redux-saga/effects'
import { getIndicators } from 'services/games'
import CorePackage from '@tusantos/compare-core-package'
import actions from './actions'

export function* GET_GAMES(data) {
  const games = data && data.payload && data.payload.games ? data.payload.games : null
  const currentFavoriteGames = yield select(state => state.games.favorite)
  const selectGame = yield select(state => state.games.selectGame)

  const currentUserMethods = yield select(state => state.methods.data)
  const tab = yield select(state => state.games.tab)
  const currentFilterMethod = yield select(state => state.games.filterMethod)
  const userOperations = yield select(state => state.user.operations)

  if (games) {
    if (userOperations && userOperations.length) {
      userOperations.forEach(order => {
        const gameId = order.sport_event_id
        const findGameIndex = games.findIndex(el => el.id === gameId)

        if (findGameIndex >= 0) {
          if (!games[findGameIndex].operations) {
            games[findGameIndex].operations = []
          }
          games[findGameIndex].operations.push(order)
        }
      })
    }

    if (currentUserMethods && currentUserMethods.length > 0 && games && games.length > 0) {
      currentUserMethods.forEach(method => {
        if (method.conditions && method.conditions.length && method.active) {
          const methodCondit = CorePackage.parseConditions(JSON.parse(method.conditions))

          if (methodCondit && methodCondit.length > 0) {
            games.forEach(game => {
              const validate = CorePackage.compareGameWithMethod(
                game,
                methodCondit,
                method.conditionType,
              )
              if (validate) {
                if (game.method) {
                  game.method.push({
                    id: method.id,
                    name: method.name,
                    color: method.color,
                    icon: method.icon || null,
                  })
                }
                if (!game.method) {
                  const newArr = []
                  newArr.push({
                    id: method.id,
                    name: method.name,
                    color: method.color,
                    icon: method.icon || null,
                  })
                  game.method = newArr
                }
              }
            })
          }
        }
      })
    }

    const filterGamesMethods = games.filter(game => {
      if (game && game.method && game.method.length > 0) {
        return game
      }
      return null
    })

    if (selectGame) {
      const findGame = games.find(game => game.id === selectGame.id)

      if (findGame) {
        yield put({
          type: 'games/SET_STATE',
          payload: {
            selectGame: findGame,
          },
        })

        yield put({
          type: 'games/GET_INDICATORS',
          payload: {
            id: findGame.id,
          },
        })
      }

      if (!findGame) {
        yield put({
          type: 'games/SET_STATE',
          payload: {
            selectGame: null,
          },
        })
      }
    }

    yield put({
      type: 'games/SET_STATE',
      payload: {
        gamesLive: games.length,
      },
    })

    yield put({
      type: 'games/SET_STATE',
      payload: {
        gamesMethods: filterGamesMethods.length,
      },
    })
    if (tab === 4) {
      const filterGames = games.filter(v => v.operations && v.operations.length > 0)
      yield put({
        type: 'games/SET_STATE',
        payload: {
          data: filterGames,
        },
      })
    }

    if (currentFavoriteGames && tab === 3) {
      const filterGames = games.filter(v => currentFavoriteGames.includes(v.id))

      yield put({
        type: 'games/SET_STATE',
        payload: {
          data: filterGames,
        },
      })
    }

    if (tab === 2) {
      let filterGames = null
      if (currentFilterMethod === 'all') {
        filterGames = games.filter(v => {
          if (v.method && v.method.length) {
            return v
          }
          return null
        })
      }

      if (currentFilterMethod !== 'all') {
        filterGames = games.filter(v => {
          if (v.method && v.method.length) {
            const mapMethod = v.method.map(el => el.id)
            if (mapMethod.includes(currentFilterMethod)) {
              return v
            }
          }
          return null
        })
      }
      yield put({
        type: 'games/SET_STATE',
        payload: {
          data: filterGames,
        },
      })
    }
    if (tab === 1) {
      yield put({
        type: 'games/SET_STATE',
        payload: {
          data: games,
        },
      })
    }
  }
}

export function* GET_INDICATORS(param) {
  const userInputRole = yield select(state => state.user.inputRole)
  const id = param.payload && param.payload.id ? param.payload.id.split(':')[2] : null
  if (id && userInputRole && userInputRole === 1) {
    yield put({
      type: 'games/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const indicators = yield call(getIndicators, id)
    yield put({
      type: 'games/SET_STATE',
      payload: {
        indicators,
      },
    })
    yield put({
      type: 'games/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* SET_OPERATIONS_GAME(payload) {
  const { result } = payload.payload

  const games = yield select(state => state.games.data)
  const findGame = games.find(el => el.id === result.sport_event_id)

  if (findGame) {
    if (!findGame.operations) {
      findGame.operations = []
    }
    findGame.operations.push(result)

    yield put({
      type: 'games/SET_STATE',
      payload: {
        data: games,
      },
    })
  }
}

export function* SET_OPERATIONS_GAME_EXIT(payload) {
  const { id, gameId } = payload.payload

  const games = yield select(state => state.games.data)
  const findGame = games.find(el => el.id === gameId)

  if (findGame) {
    if (!findGame.operations) {
      findGame.operations = []
    }
    const fIndex = findGame.operations.findIndex(el => el.id === id)

    if (fIndex >= 0) {
      findGame.operations = [
        ...findGame.operations.slice(0, fIndex),
        ...findGame.operations.slice(fIndex + 1),
      ]
    }

    yield put({
      type: 'games/SET_STATE',
      payload: {
        data: games,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_GAMES, GET_GAMES)])
  yield all([takeEvery(actions.GET_INDICATORS, GET_INDICATORS)])
  yield all([takeEvery(actions.SET_OPERATIONS_GAME, SET_OPERATIONS_GAME)])
  yield all([takeEvery(actions.SET_OPERATIONS_GAME_EXIT, SET_OPERATIONS_GAME_EXIT)])
}
