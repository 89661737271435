import { all, takeEvery, call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import {
  getMethods,
  deleteMethods,
  createMethods,
  updateMethods,
  getOperationsMethod,
} from 'services/methods'
import actions from './actions'

export function* GET_METHODS() {
  const methods = yield call(getMethods)
  if (methods) {
    yield put({
      type: 'methods/SET_STATE',
      payload: {
        data: methods,
      },
    })
  }
  if (!methods) {
    yield put({
      type: 'methods/SET_STATE',
      payload: {
        data: [],
      },
    })
  }
}

export function* CREATE_METHOD(data) {
  const respCreateMethods = yield call(createMethods, data.payload.data)
  if (respCreateMethods) {
    yield history.push('/dashboard/methods/show')
    notification.success({
      message: 'Criado',
      description: 'Método criado.',
    })
  }
}

export function* UPDATE_METHOD(data) {
  const respUpdateMethods = yield call(updateMethods, data.payload.data)
  if (respUpdateMethods) {
    yield history.push('/dashboard/methods/show')
    notification.success({
      message: 'Atualizado',
      description: 'Método atualizado.',
    })
  }
}

export function* DELETE_METHOD(data) {
  const methods = yield call(deleteMethods, data.payload.id)
  if (methods) {
    yield put({
      type: 'methods/GET_METHODS',
    })
  }
}

export function* GET_METHODS_ORDERS() {
  const methods = yield call(getOperationsMethod)
  if (methods) {
    yield put({
      type: 'methods/SET_STATE',
      payload: {
        methodOrders: methods,
      },
    })
  }
  if (!methods) {
    yield put({
      type: 'methods/SET_STATE',
      payload: {
        methodOrders: [],
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_METHODS, GET_METHODS),
    takeEvery(actions.CREATE_METHOD, CREATE_METHOD),
    takeEvery(actions.UPDATE_METHOD, UPDATE_METHOD),
    takeEvery(actions.DELETE_METHOD, DELETE_METHOD),
    takeEvery(actions.GET_METHODS_ORDERS, GET_METHODS_ORDERS),
  ])
}
