import { notification } from 'antd'
import api from './api'

// eslint-disable-next-line import/prefer-default-export
export async function getGames() {
  try {
    const resp = await api.get('/games/resume')

    if (resp && resp.data && resp.data.length) {
      const games = resp.data
      const filterGames = games.filter(el => el.status !== 'delayed')
      return filterGames
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha nos jogos',
      description: 'Não conseguimos pegar os jogos em andamento.',
    })
  }
}

export async function getGame(id, minute = '1') {
  try {
    const pMinute = parseInt(minute, 10)
    let period = pMinute >= 45 ? 2 : 1
    if (pMinute === 500) {
      period = 0
    }
    const resp = await api.get(`/games/${id}?minute=${pMinute}&period=${period}`)

    if (resp && resp.data) {
      return resp.data
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha nos jogos',
      description: 'Não conseguimos pegar os dados do jogo, tente novamente.',
    })
  }
}

export async function getPrelive(id) {
  try {
    const resp = await api.get(`/games/prelive/${id}`)

    if (resp && resp.data) {
      return resp.data
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha nos jogos',
      description: 'Não conseguimos pegar os dados do jogo, tente novamente.',
    })
  }
}

export async function getGameGraph(id) {
  try {
    const resp = await api.get(`/games/graph/${id}`)

    if (resp && resp.data) {
      return resp.data
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha nos jogos',
      description: 'Não conseguimos pegar os dados do jogo, tente novamente.',
    })
  }
}

export async function getIndicators(id) {
  try {
    const resp = await api.get(`/games/indicators/${id}`)

    if (resp && resp.data) {
      return resp.data
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha',
      description: 'Não conseguimos pegar os indicadores.',
    })
  }
}

export async function getPastGames(page, dateStart, dateEnd, teamId, order = null) {
  try {
    const dateParam = dateStart && dateEnd ? `&startDate=${dateStart}&endDate=${dateEnd}` : ''
    const ppage = page || 1
    const orderBy = order === 'ascend' ? 'ASC' : 'DESC'
    const teamIdParam = teamId ? `&teamId=${teamId}` : ''

    const resp = await api.get(
      `/games/search?page=${ppage}&order=${orderBy}${dateParam}${teamIdParam}`,
    )
    if (resp && resp.data && resp.data) {
      return resp.data
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha nos jogos',
      description: 'Não conseguimos pegar os jogos passados.',
    })
  }
}

export async function searchPastGames(query, page) {
  try {
    const ppage = page || 1

    const resp = await api.post('/search/past', {
      query,
      page: ppage,
    })
    if (resp && resp.data && resp.data) {
      return resp.data
    }

    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha nos jogos',
      description: 'Não conseguimos pegar os jogos passados.',
    })
  }
}
