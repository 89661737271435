export const betfairTraderBetButton = [3, 4, 5, 6, 7, 8, 9, 15, 111, 112]

export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Jogos',
    },
    {
      title: 'Jogos ao vivo',
      key: 'games',
      icon: 'fe fe-bookmark',
      role: [1, 2, 3, 4, 5, 6, 7, 8, 9, 15, 111, 112],
      url: '/dashboard/games',
    },
    {
      title: 'Painel operacional',
      key: 'dash games',
      icon: 'fe fe-bookmark',
      role: [3, 4, 5, 6, 7, 8, 9, 15, 111, 112],
      url: '/dashboard/dashGames',
    },
    {
      title: 'Lucy',
      key: 'past games',
      icon: 'fe fe-bookmark',
      role: [6, 7, 8, 9, 15, 112],
      children: [
        {
          title: 'Jogos passados',
          key: 'past games lucy',
          icon: 'fe fe-bookmark',
          role: [6, 7, 8, 9, 15, 112],
          url: '/dashboard/pastgames',
        },
        {
          title: 'Filtro jogos',
          key: 'search past lucy',
          icon: 'fe fe-bookmark',
          role: [6, 7, 8, 9, 15, 112],
          url: '/dashboard/pastgames/search',
        },
      ],
    },
    {
      title: 'Odds pré-live',
      key: 'oddsprelive',
      icon: 'fe fe-bookmark',
      role: [3, 4, 6, 7, 8, 9, 15, 111, 112],
      url: '/dashboard/prelive',
    },
    {
      title: 'Zeus 2.0',
      key: 'search',
      icon: 'fe fe-search',
      role: [2, 3, 4, 6, 7, 8, 9, 15, 112],
      children: [
        {
          title: 'Busca simples',
          key: 'games search',
          role: [2, 3, 4, 6, 7, 8, 9, 15, 112],
          url: '/dashboard/games/search/basic',
        },
        {
          title: 'Busca avançada',
          key: 'games search advanced',
          role: [2, 3, 4, 6, 7, 8, 9, 15, 112],
          url: '/dashboard/games/search/advanced',
        },
      ],
    },
    {
      title: 'Scanner',
      key: 'methods',
      icon: 'lnr lnr-chart-bars',
      role: [3, 4, 5, 6, 7, 8, 9, 15, 111, 112],
      children: [
        {
          title: 'Meus métodos',
          key: 'my methods',
          role: [3, 4, 5, 6, 7, 8, 9, 15, 111, 112],
          url: '/dashboard/methods/show',
        },
        {
          title: 'Criar métodos',
          key: 'create methods',
          role: [3, 4, 5, 6, 7, 8, 9, 15, 111, 112],
          url: '/dashboard/methods/create',
        },
        {
          title: 'Filtros no card',
          key: 'filtrosnocard',
          role: [7, 8, 9, 15, 111, 112],
          url: '/dashboard/filterCard',
        },
      ],
    },
    {
      title: 'Gestão',
      key: 'orders',
      icon: 'lnr lnr-chart-bars',
      role: [111, 7, 8, 9, 15, 112],
      children: [
        {
          title: 'Métodos',
          key: 'my orders methods',
          role: [111, 7, 8, 9, 15, 112],
          url: '/dashboard/orders/methods/show',
        },
        {
          title: 'Ordens abertas',
          key: 'my orders open',
          role: [111, 7, 8, 9, 15, 112],
          url: '/dashboard/orders/open',
        },
        {
          title: 'Minhas ordens',
          key: 'my orders past',
          role: [111, 7, 8, 9, 15, 112],
          url: '/dashboard/orders',
        },
      ],
    },
    {
      title: 'Bot Sonny',
      key: 'botsony',
      icon: 'lnr lnr-chart-bars',
      role: [9, 15],
      children: [
        {
          title: 'Informações',
          key: 'infos sony',
          role: [9, 15],
          url: '/dashboard/sony',
        },
        {
          title: 'Meus métodos',
          key: 'my methods sony',
          role: [9, 15],
          url: '/dashboard/sony/methods/show',
        },
        {
          title: 'Criar métodos',
          key: 'create methods sony',
          role: [9, 15],
          url: '/dashboard/sony/methods/create',
        },
        {
          title: 'Configurações',
          key: 'configs sony',
          role: [9, 15],
          url: '/dashboard/sony/configs',
        },
      ],
    },
    {
      title: 'Compartilhar métodos',
      key: 'share methods sony',
      icon: 'fe fe-bookmark',
      role: [15],
      url: '/dashboard/sony/shared',
    },
    {
      title: 'Admin',
      key: 'admin',
      icon: 'lnr lnr-chart-bars',
      role: [50],
      children: [
        {
          title: 'Usuários',
          key: 'infos sony',
          role: [50],
          url: '/admin',
        },
      ],
    },
    {
      title: 'Admin',
      key: 'adminfulltips',
      icon: 'lnr lnr-chart-bars',
      role: [55],
      children: [
        {
          title: 'Sonny',
          key: 'sony admin',
          role: [55],
          url: '/dashboard/fulltips',
        },
      ],
    },
    {
      title: 'Admin',
      key: 'indicadores',
      icon: 'lnr lnr-chart-bars',
      role: [12],
      children: [
        {
          title: 'Indicadores',
          key: 'indicadores ind',
          role: [12],
          url: '/indicadores',
        },
      ],
    },
  ]
}
