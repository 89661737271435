import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Footer extends React.Component {
  render() {
    const {
      settings: { isContentNoMaxWidth },
    } = this.props
    return (
      <div
        className={classNames(style.footer, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        <div className={style.inner}>
          <div className="row">
            <div className="col-md-8">
              <p>
                <strong>Trading Tool</strong>
              </p>
              <p>&copy; 2020 Trading Tool - References for success</p>
            </div>
            <div className="col-md-4">
              <div className={style.logo}>
                <img src={`${process.env.PUBLIC_URL}/resources/images/logofooter.png`} alt="Trading Tool" />
                <div className={style.logoName}>Trading Tool</div>
                <div className={style.logoDescr}>References for success</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
