import { notification } from 'antd'
import api from './api'

// eslint-disable-next-line import/prefer-default-export
export async function login(email, password, recaptcha) {
  try {
    const resp = await api.post('/auth', {
      email,
      password,
      recaptcha,
    })
    if (resp && resp.data) {
      return resp.data
    }
    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha no login',
      description: 'Verifique seu email/senha e tente novamente.',
    })
  }
}

export async function indicators(token) {
  try {
    const resp = await api.get('/indicators', {
      headers: {
        authorization: token,
      },
    })
    if (resp && resp.data) {
      return resp.data
    }
    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha nos indicadores',
    })
  }
}

export async function register(email, password, name, lastname, recaptcha) {
  try {
    const data = {
      email,
      password,
      recaptcha,
    }

    if (name) {
      data.firstName = name
    }

    if (lastname) {
      data.lastName = lastname
    }

    const resp = await api.post('/user', data)

    if (resp && resp.data) {
      return resp.data
    }
    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha no login',
      description: 'Verifique seu email/senha e tente novamente.',
    })
  }
}

export async function getUserDetails(id) {
  try {
    const resp = await api.get(`/user/${id}`)
    if (resp && resp.data) {
      return resp.data
    }
    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha',
      description: 'Não conseguimos pegar as informações do usuario.',
    })
  }
}

export async function getUserCountMethods(id) {
  try {
    const resp = await api.post(`/user/upusermethodscount`, {
      userId: id,
    })
    if (resp && resp.data) {
      return resp.data
    }
    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha',
      description: 'Não conseguimos pegar as informações do usuario.',
    })
  }
}

export async function transferMethods(userM, userR) {
  try {
    const resp = await api.post(`/user/upusermethods`, {
      userM,
      userR,
    })
    if (resp && resp.data) {
      return resp.data
    }
    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha',
      description: 'Aconteceu um erro, tente novamente.',
    })
  }
}

export async function updateUser(id, role, inputRole, expDate, password) {
  try {
    const resp = await api.post('/user/upuser', {
      id,
      role,
      inputRole,
      expDate,
      password,
    })
    if (resp && resp.data) {
      return resp.data
    }
    return null
  } catch (error) {
    return notification.warning({
      message: 'Falha',
      description: 'Não conseguimos atualizar o usuario.',
    })
  }
}

export async function showConfigs() {
  const resp = await api.get('/user/configs')
  if (resp && resp.data) {
    return resp.data
  }
  return null
}

export async function updateConfigs(loginP, password) {
  const resp = await api.post('/user/updatePasswordTB', {
    login: loginP,
    password,
  })
  if (resp && resp.data) {
    return resp.data
  }
  return null
}
